import { addI18n } from "tinymce/tinymce";

addI18n("de", {
  Redo: "Redo",
  Undo: "R\xfcckg\xe4ngig machen",
  Cut: "Ausschneiden",
  Copy: "Kopieren",
  Paste: "Einf\xfcgen",
  "Select all": "Alles ausw\xe4hlen",
  "New document": "Neues Dokument",
  Ok: "Ok",
  Cancel: "Abbrechen",
  "Visual aids": "Visuelle Hilfen",
  Bold: "Fett",
  Italic: "Kursiv",
  Underline: "Unterstrichen",
  Strikethrough: "Durchgestrichen",
  Superscript: "Hochgestellt",
  Subscript: "Tiefgestellt",
  "Clear formatting": "Formatierung entfernen",
  Remove: "Entfernen",
  "Align left": "Linksb\xfcndig ausrichten",
  "Align center": "Zentrieren",
  "Align right": "Rechtsb\xfcndig ausrichten",
  "No alignment": "Keine Ausrichtung",
  Justify: "Blocksatz",
  "Bullet list": "Aufz\xe4hlung",
  "Numbered list": "Nummerierte Liste",
  "Decrease indent": "Einzug verkleinern",
  "Increase indent": "Einzug vergr\xf6\xdfern",
  Close: "Schlie\xdfen",
  Formats: "Formate",
  "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X/C/V keyboard shortcuts instead.":
    "Ihr Browser unterst\xfctzt leider keinen direkten Zugriff auf die Zwischenablage. Bitte benutzen Sie die Tastenkombinationen Strg+X/C/V.",
  Headings: "\xdcberschriften",
  "Heading 1": "\xdcberschrift 1",
  "Heading 2": "\xdcberschrift 2",
  "Heading 3": "\xdcberschrift 3",
  "Heading 4": "\xdcberschrift 4",
  "Heading 5": "\xdcberschrift 5",
  "Heading 6": "\xdcberschrift 6",
  Preformatted: "Vorformatiert",
  Div: "Div",
  Pre: "Pre",
  Code: "Code",
  Paragraph: "Absatz",
  Blockquote: "Blockzitat",
  Inline: "Zeichenformate",
  Blocks: "Bl\xf6cke",
  "Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.":
    "Einf\xfcgen ist nun im unformatierten Textmodus. Inhalte werden ab jetzt als unformatierter Text eingef\xfcgt, bis Sie diese Einstellung wieder deaktivieren.",
  Fonts: "Schriftarten",
  "Font sizes": "Schriftgr\xf6\xdfen",
  Class: "Klasse",
  "Browse for an image": "Bild...",
  OR: "ODER",
  "Drop an image here": "Bild hier ablegen",
  Upload: "Hochladen",
  "Uploading image": "Bild wird hochgeladen",
  Block: "Blocksatz",
  Align: "Ausrichtung",
  Default: "Standard",
  Circle: "Kreis",
  Disc: "Scheibe",
  Square: "Rechteck",
  "Lower Alpha": "Lateinisches Alphabet in Kleinbuchstaben",
  "Lower Greek": "Griechische Kleinbuchstaben",
  "Lower Roman": "Kleiner r\xf6mischer Buchstabe",
  "Upper Alpha": "Lateinisches Alphabet in Gro\xdfbuchstaben",
  "Upper Roman": "Gro\xdfer r\xf6mischer Buchstabe",
  "Anchor...": "Textmarke",
  Anchor: "Anker",
  Name: "Name",
  ID: "ID",
  "ID should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.":
    "Die ID muss mit einem Buchstaben beginnen gefolgt von Buchstaben, Zahlen, Bindestrichen, Punkten, Doppelpunkten oder Unterstrichen.",
  "You have unsaved changes are you sure you want to navigate away?":
    "Die \xc4nderungen wurden noch nicht gespeichert. Sind Sie sicher, dass Sie diese Seite verlassen wollen?",
  "Restore last draft": "Letzten Entwurf wiederherstellen",
  "Special character...": "Sonderzeichen...",
  "Special Character": "Sonderzeichen",
  "Source code": "Quellcode",
  "Insert/Edit code sample": "Codebeispiel einf\xfcgen/bearbeiten",
  Language: "Sprache",
  "Code sample...": "Codebeispiel...",
  "Left to right": "Von links nach rechts",
  "Right to left": "Von rechts nach links",
  Title: "Titel",
  Fullscreen: "Vollbild",
  Action: "Aktion",
  Shortcut: "Tastenkombination",
  Help: "Hilfe",
  Address: "Adresse",
  "Focus to menubar": "Fokus auf Men\xfcleiste",
  "Focus to toolbar": "Fokus auf Symbolleiste",
  "Focus to element path": "Fokus auf Elementpfad",
  "Focus to contextual toolbar": "Fokus auf kontextbezogene Symbolleiste",
  "Insert link (if link plugin activated)": "Link einf\xfcgen (wenn Link-Plugin aktiviert ist)",
  "Save (if save plugin activated)": "Speichern (wenn Save-Plugin aktiviert ist)",
  "Find (if searchreplace plugin activated)": "Suchen (wenn Suchen/Ersetzen-Plugin aktiviert ist)",
  "Plugins installed ({0}):": "Installierte Plugins ({0}):",
  "Premium plugins:": "Premium-Plugins:",
  "Learn more...": "Erfahren Sie mehr dazu...",
  "You are using {0}": "Sie verwenden {0}",
  Plugins: "Plugins",
  "Handy Shortcuts": "Praktische Tastenkombinationen",
  "Horizontal line": "Horizontale Linie",
  "Insert/edit image": "Bild einf\xfcgen/bearbeiten",
  "Alternative description": "Alternative Beschreibung",
  Accessibility: "Barrierefreiheit",
  "Image is decorative": "Bild ist dekorativ",
  Source: "Quelle",
  Dimensions: "Abmessungen",
  "Constrain proportions": "Seitenverh\xe4ltnis beibehalten",
  General: "Allgemein",
  Advanced: "Erweitert",
  Style: "Formatvorlage",
  "Vertical space": "Vertikaler Raum",
  "Horizontal space": "Horizontaler Raum",
  Border: "Rahmen",
  "Insert image": "Bild einf\xfcgen",
  "Image...": "Bild...",
  "Image list": "Bildliste",
  Resize: "Skalieren",
  "Insert date/time": "Datum/Uhrzeit einf\xfcgen",
  "Date/time": "Datum/Uhrzeit",
  "Insert/edit link": "Link einf\xfcgen/bearbeiten",
  "Text to display": "Anzuzeigender Text",
  Url: "URL",
  "Open link in...": "Link \xf6ffnen in...",
  "Current window": "Aktuelles Fenster",
  None: "Keine",
  "New window": "Neues Fenster",
  "Open link": "Link \xf6ffnen",
  "Remove link": "Link entfernen",
  Anchors: "Anker",
  "Link...": "Link...",
  "Paste or type a link": "Link einf\xfcgen oder eingeben",
  "The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?":
    "Diese URL scheint eine E-Mail-Adresse zu sein. M\xf6chten Sie das dazu ben\xf6tigte mailto: voranstellen?",
  "The URL you entered seems to be an external link. Do you want to add the required http:// prefix?":
    "Diese URL scheint ein externer Link zu sein. M\xf6chten Sie das dazu ben\xf6tigte http:// voranstellen?",
  "The URL you entered seems to be an external link. Do you want to add the required https:// prefix?":
    "Die eingegebene URL scheint ein externer Link zu sein. Soll das fehlende https:// davor erg\xe4nzt werden?",
  "Link list": "Linkliste",
  "Insert video": "Video einf\xfcgen",
  "Insert/edit video": "Video einf\xfcgen/bearbeiten",
  "Insert/edit media": "Medien einf\xfcgen/bearbeiten",
  "Alternative source": "Alternative Quelle",
  "Alternative source URL": "URL der alternativen Quelle",
  "Media poster (Image URL)": "Medienposter (Bild-URL)",
  "Paste your embed code below:": "F\xfcgen Sie Ihren Einbettungscode unten ein:",
  Embed: "Einbettung",
  "Media...": "Medien...",
  "Nonbreaking space": "Gesch\xfctztes Leerzeichen",
  "Page break": "Seitenumbruch",
  "Paste as text": "Als Text einf\xfcgen",
  Preview: "Vorschau",
  Print: "Drucken",
  "Print...": "Drucken...",
  Save: "Speichern",
  Find: "Suchen",
  "Replace with": "Ersetzen durch",
  Replace: "Ersetzen",
  "Replace all": "Alle ersetzen",
  Previous: "Vorherige",
  Next: "N\xe4chste",
  "Find and Replace": "Suchen und Ersetzen",
  "Find and replace...": "Suchen und ersetzen...",
  "Could not find the specified string.": "Die angegebene Zeichenfolge wurde nicht gefunden.",
  "Match case": "Gro\xdf-/Kleinschreibung beachten",
  "Find whole words only": "Nur ganze W\xf6rter suchen",
  "Find in selection": "In Auswahl suchen",
  "Insert table": "Tabelle einf\xfcgen",
  "Table properties": "Tabelleneigenschaften",
  "Delete table": "Tabelle l\xf6schen",
  Cell: "Zelle",
  Row: "Zeile",
  Column: "Spalte",
  "Cell properties": "Zelleigenschaften",
  "Merge cells": "Zellen verbinden",
  "Split cell": "Zelle aufteilen",
  "Insert row before": "Neue Zeile davor einf\xfcgen",
  "Insert row after": "Neue Zeile danach einf\xfcgen",
  "Delete row": "Zeile l\xf6schen",
  "Row properties": "Zeileneigenschaften",
  "Cut row": "Zeile ausschneiden",
  "Cut column": "Spalte ausschneiden",
  "Copy row": "Zeile kopieren",
  "Copy column": "Spalte kopieren",
  "Paste row before": "Zeile davor einf\xfcgen",
  "Paste column before": "Spalte davor einf\xfcgen",
  "Paste row after": "Zeile danach einf\xfcgen",
  "Paste column after": "Spalte danach einf\xfcgen",
  "Insert column before": "Neue Spalte davor einf\xfcgen",
  "Insert column after": "Neue Spalte danach einf\xfcgen",
  "Delete column": "Spalte l\xf6schen",
  Cols: "Spalten",
  Rows: "Zeilen",
  Width: "Breite",
  Height: "H\xf6he",
  "Cell spacing": "Zellenabstand",
  "Cell padding": "Zelleninnenabstand",
  "Row clipboard actions": "Zeilen-Zwischenablage-Aktionen",
  "Column clipboard actions": "Spalten-Zwischenablage-Aktionen",
  "Table styles": "Tabellenstil",
  "Cell styles": "Zellstil",
  "Column header": "Spaltenkopf",
  "Row header": "Zeilenkopf",
  "Table caption": "Tabellenbeschriftung",
  Caption: "Beschriftung",
  "Show caption": "Beschriftung anzeigen",
  Left: "Links",
  Center: "Zentriert",
  Right: "Rechts",
  "Cell type": "Zelltyp",
  Scope: "Bereich",
  Alignment: "Ausrichtung",
  "Horizontal align": "Horizontal ausrichten",
  "Vertical align": "Vertikal ausrichten",
  Top: "Oben",
  Middle: "Mitte",
  Bottom: "Unten",
  "Header cell": "Kopfzelle",
  "Row group": "Zeilengruppe",
  "Column group": "Spaltengruppe",
  "Row type": "Zeilentyp",
  Header: "Kopfzeile",
  Body: "Inhalt",
  Footer: "Fu\xdfzeile",
  "Border color": "Rahmenfarbe",
  Solid: "Durchgezogen",
  Dotted: "Gepunktet",
  Dashed: "Gestrichelt",
  Double: "Doppelt",
  Groove: "Gekantet",
  Ridge: "Eingeritzt",
  Inset: "Eingelassen",
  Outset: "Hervorstehend",
  Hidden: "Unsichtbar",
  "Insert template...": "Vorlage einf\xfcgen...",
  Templates: "Vorlagen",
  Template: "Vorlage",
  "Insert Template": "Vorlage einf\xfcgen",
  "Text color": "Textfarbe",
  "Background color": "Hintergrundfarbe",
  "Custom...": "Benutzerdefiniert...",
  "Custom color": "Benutzerdefinierte Farbe",
  "No color": "Keine Farbe",
  "Remove color": "Farbauswahl aufheben",
  "Show blocks": "Bl\xf6cke anzeigen",
  "Show invisible characters": "Unsichtbare Zeichen anzeigen",
  "Word count": "Anzahl der W\xf6rter",
  Count: "Anzahl",
  Document: "Dokument",
  Selection: "Auswahl",
  Words: "W\xf6rter",
  "Words: {0}": "Wortzahl: {0}",
  "{0} words": "{0} W\xf6rter",
  File: "Datei",
  Edit: "Bearbeiten",
  Insert: "Einf\xfcgen",
  View: "Ansicht",
  Format: "Format",
  Table: "Tabelle",
  Tools: "Werkzeuge",
  "Powered by {0}": "Betrieben von {0}",
  "Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help":
    "Rich-Text-Bereich. Dr\xfccken Sie Alt+F9 f\xfcr das Men\xfc. Dr\xfccken Sie Alt+F10 f\xfcr die Symbolleiste. Dr\xfccken Sie Alt+0 f\xfcr Hilfe.",
  "Image title": "Bildtitel",
  "Border width": "Rahmenbreite",
  "Border style": "Rahmenstil",
  Error: "Fehler",
  Warn: "Warnung",
  Valid: "G\xfcltig",
  "To open the popup, press Shift+Enter":
    "Dr\xfccken Sie Umschalt+Eingabe, um das Popup-Fenster zu \xf6ffnen.",
  "Rich Text Area": "Rich-Text-Area",
  "Rich Text Area. Press ALT-0 for help.": "Rich-Text-Bereich. Dr\xfccken Sie Alt+0 f\xfcr Hilfe.",
  "System Font": "Betriebssystemschriftart",
  "Failed to upload image: {0}": "Bild konnte nicht hochgeladen werden: {0}",
  "Failed to load plugin: {0} from url {1}": "Plugin konnte nicht geladen werden: {0} von URL {1}",
  "Failed to load plugin url: {0}": "Plugin-URL konnte nicht geladen werden: {0}",
  "Failed to initialize plugin: {0}": "Plugin konnte nicht initialisiert werden: {0}",
  example: "Beispiel",
  Search: "Suchen",
  All: "Alle",
  Currency: "W\xe4hrung",
  Text: "Text",
  Quotations: "Anf\xfchrungszeichen",
  Mathematical: "Mathematisch",
  "Extended Latin": "Erweitertes Latein",
  Symbols: "Symbole",
  Arrows: "Pfeile",
  "User Defined": "Benutzerdefiniert",
  "dollar sign": "Dollarzeichen",
  "currency sign": "W\xe4hrungssymbol",
  "euro-currency sign": "Eurozeichen",
  "colon sign": "Doppelpunkt",
  "cruzeiro sign": "Cruzeirozeichen",
  "french franc sign": "Franczeichen",
  "lira sign": "Lirezeichen",
  "mill sign": "Millzeichen",
  "naira sign": "Nairazeichen",
  "peseta sign": "Pesetazeichen",
  "rupee sign": "Rupiezeichen",
  "won sign": "Wonzeichen",
  "new sheqel sign": "Schekelzeichen",
  "dong sign": "Dongzeichen",
  "kip sign": "Kipzeichen",
  "tugrik sign": "Tugrikzeichen",
  "drachma sign": "Drachmezeichen",
  "german penny symbol": "Pfennigzeichen",
  "peso sign": "Pesozeichen",
  "guarani sign": "Guaranizeichen",
  "austral sign": "Australzeichen",
  "hryvnia sign": "Hrywnjazeichen",
  "cedi sign": "Cedizeichen",
  "livre tournois sign": "Livrezeichen",
  "spesmilo sign": "Spesmilozeichen",
  "tenge sign": "Tengezeichen",
  "indian rupee sign": "Indisches Rupiezeichen",
  "turkish lira sign": "T\xfcrkisches Lirazeichen",
  "nordic mark sign": "Zeichen nordische Mark",
  "manat sign": "Manatzeichen",
  "ruble sign": "Rubelzeichen",
  "yen character": "Yenzeichen",
  "yuan character": "Yuanzeichen",
  "yuan character, in hong kong and taiwan": "Yuanzeichen in Hongkong und Taiwan",
  "yen/yuan character variant one": "Yen-/Yuanzeichen Variante 1",
  Emojis: "Emojis",
  "Emojis...": "Emojis...",
  "Loading emojis...": "Lade Emojis...",
  "Could not load emojis": "Emojis konnten nicht geladen werden",
  People: "Menschen",
  "Animals and Nature": "Tiere und Natur",
  "Food and Drink": "Essen und Trinken",
  Activity: "Aktivit\xe4t",
  "Travel and Places": "Reisen und Orte",
  Objects: "Objekte",
  Flags: "Flaggen",
  Characters: "Zeichen",
  "Characters (no spaces)": "Zeichen (ohne Leerzeichen)",
  "{0} characters": "{0}\xa0Zeichen",
  "Error: Form submit field collision.": "Fehler: Kollision der Formularbest\xe4tigungsfelder.",
  "Error: No form element found.": "Fehler: Kein Formularelement gefunden.",
  "Color swatch": "Farbpalette",
  "Color Picker": "Farbwahl",
  "Invalid hex color code: {0}": "Ung\xfcltiger Hexadezimal-Farbwert: {0}",
  "Invalid input": "Ung\xfcltige Eingabe",
  R: "R",
  "Red component": "Rotanteil",
  G: "G",
  "Green component": "Gr\xfcnanteil",
  B: "B",
  "Blue component": "Blauanteil",
  "#": "#",
  "Hex color code": "Hexadezimal-Farbwert",
  "Range 0 to 255": "Spanne 0 bis 255",
  Turquoise: "T\xfcrkis",
  Green: "Gr\xfcn",
  Blue: "Blau",
  Purple: "Violett",
  "Navy Blue": "Marineblau",
  "Dark Turquoise": "Dunkelt\xfcrkis",
  "Dark Green": "Dunkelgr\xfcn",
  "Medium Blue": "Mittleres Blau",
  "Medium Purple": "Mittelviolett",
  "Midnight Blue": "Mitternachtsblau",
  Yellow: "Gelb",
  Orange: "Orange",
  Red: "Rot",
  "Light Gray": "Hellgrau",
  Gray: "Grau",
  "Dark Yellow": "Dunkelgelb",
  "Dark Orange": "Dunkelorange",
  "Dark Red": "Dunkelrot",
  "Medium Gray": "Mittelgrau",
  "Dark Gray": "Dunkelgrau",
  "Light Green": "Hellgr\xfcn",
  "Light Yellow": "Hellgelb",
  "Light Red": "Hellrot",
  "Light Purple": "Helllila",
  "Light Blue": "Hellblau",
  "Dark Purple": "Dunkellila",
  "Dark Blue": "Dunkelblau",
  Black: "Schwarz",
  White: "Wei\xdf",
  "Switch to or from fullscreen mode": "Vollbildmodus umschalten",
  "Open help dialog": "Hilfe-Dialog \xf6ffnen",
  history: "Historie",
  styles: "Stile",
  formatting: "Formatierung",
  alignment: "Ausrichtung",
  indentation: "Einr\xfcckungen",
  Font: "Schriftart",
  Size: "Schriftgr\xf6\xdfe",
  "More...": "Mehr...",
  "Select...": "Auswahl...",
  Preferences: "Einstellungen",
  Yes: "Ja",
  No: "Nein",
  "Keyboard Navigation": "Tastaturnavigation",
  Version: "Version",
  "Code view": "Code Ansicht",
  "Open popup menu for split buttons": "\xd6ffne Popup Menge um Buttons zu trennen",
  "List Properties": "Liste Eigenschaften",
  "List properties...": "Liste Eigenschaften",
  "Start list at number": "Beginne Liste mit Nummer",
  "Line height": "Liniendicke",
  "Dropped file type is not supported": "Hereingezogener Dateityp wird nicht unterst\xfctzt",
  "Loading...": "Wird geladen...",
  "ImageProxy HTTP error: Rejected request": "Image Proxy HTTP Fehler: Abgewiesene Anfrage",
  "ImageProxy HTTP error: Could not find Image Proxy":
    "Image Proxy HTTP Fehler: Kann Image Proxy nicht finden",
  "ImageProxy HTTP error: Incorrect Image Proxy URL":
    "Image Proxy HTTP Fehler: Falsche Image Proxy URL",
  "ImageProxy HTTP error: Unknown ImageProxy error":
    "Image Proxy HTTP Fehler: Unbekannter Image Proxy Fehler",
});

addI18n("fr", {
  Redo: "R\xe9tablir",
  Undo: "Annuler",
  Cut: "Couper",
  Copy: "Copier",
  Paste: "Coller",
  "Select all": "S\xe9lectionner tout",
  "New document": "Nouveau document",
  Ok: "OK",
  Cancel: "Annuler",
  "Visual aids": "Aides visuelles",
  Bold: "Gras",
  Italic: "Italique",
  Underline: "Soulign\xe9",
  Strikethrough: "Barr\xe9",
  Superscript: "Exposant",
  Subscript: "Indice",
  "Clear formatting": "Effacer la mise en forme",
  Remove: "Retir\xe9",
  "Align left": "Aligner \xe0 gauche",
  "Align center": "Centrer",
  "Align right": "Aligner \xe0 droite",
  "No alignment": "Aucun alignement",
  Justify: "Justifier",
  "Bullet list": "Liste \xe0 puces",
  "Numbered list": "Liste num\xe9rot\xe9e",
  "Decrease indent": "R\xe9duire le retrait",
  "Increase indent": "Augmenter le retrait",
  Close: "Fermer",
  Formats: "Formats",
  "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X/C/V keyboard shortcuts instead.":
    "Votre navigateur ne supporte pas l\u2019acc\xe8s direct au presse-papiers. Merci d'utiliser les raccourcis clavier Ctrl+X/C/V.",
  Headings: "Titres",
  "Heading 1": "Titre\xa01",
  "Heading 2": "Titre\xa02",
  "Heading 3": "Titre\xa03",
  "Heading 4": "Titre\xa04",
  "Heading 5": "Titre\xa05",
  "Heading 6": "Titre\xa06",
  Preformatted: "Pr\xe9format\xe9",
  Div: "Div",
  Pre: "Pr\xe9format\xe9",
  Code: "Code",
  Paragraph: "Paragraphe",
  Blockquote: "Bloc de citation",
  Inline: "En ligne",
  Blocks: "Blocs",
  "Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.":
    "Le collage est maintenant en mode texte brut. Les contenus seront coll\xe9s sans retenir les formatages jusqu'\xe0 ce que vous d\xe9sactivez cette option.",
  Fonts: "Polices",
  "Font sizes": "Tailles de police",
  Class: "Classe",
  "Browse for an image": "Rechercher une image",
  OR: "OU",
  "Drop an image here": "D\xe9poser une image ici",
  Upload: "T\xe9l\xe9charger",
  "Uploading image": "T\xe9l\xe9versement d'une image",
  Block: "Bloc",
  Align: "Aligner",
  Default: "Par d\xe9faut",
  Circle: "Cercle",
  Disc: "Disque",
  Square: "Carr\xe9",
  "Lower Alpha": "Alphabet en minuscules",
  "Lower Greek": "Alphabet grec en minuscules",
  "Lower Roman": "Chiffre romain inf\xe9rieur",
  "Upper Alpha": "Alphabet en majuscules",
  "Upper Roman": "Chiffre romain sup\xe9rieur",
  "Anchor...": "Ancre...",
  Anchor: "Ancre",
  Name: "Nom",
  ID: "ID",
  "ID should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.":
    "L'ID doit commencer par une lettre, suivie uniquement par des lettres, num\xe9ros, tirets, points, deux-points et underscores.",
  "You have unsaved changes are you sure you want to navigate away?":
    "Vous avez des modifications non enregistr\xe9es, \xeates-vous s\xfbr de vouloir quitter la page\xa0?",
  "Restore last draft": "Restaurer le dernier brouillon",
  "Special character...": "Caract\xe8re sp\xe9cial...",
  "Special Character": "Caract\xe8re sp\xe9cial",
  "Source code": "Code source",
  "Insert/Edit code sample": "Ins\xe9rer / modifier un bloc de code",
  Language: "Langue",
  "Code sample...": "Exemple de code...",
  "Left to right": "De gauche \xe0 droite",
  "Right to left": "De droite \xe0 gauche",
  Title: "Titre",
  Fullscreen: "Plein \xe9cran",
  Action: "Action",
  Shortcut: "Raccourci",
  Help: "Aide",
  Address: "Adresse",
  "Focus to menubar": "Mettre le focus sur la barre de menu",
  "Focus to toolbar": "Mettre le focus sur la barre d'outils",
  "Focus to element path": "Mettre le focus sur le chemin vers l'\xe9l\xe9ment",
  "Focus to contextual toolbar": "Mettre le focus sur la barre d'outils contextuelle",
  "Insert link (if link plugin activated)": "Ins\xe9rer un lien (si le plug-in link est activ\xe9)",
  "Save (if save plugin activated)": "Enregistrer (si le plug-in save est activ\xe9)",
  "Find (if searchreplace plugin activated)":
    "Rechercher (si le plug-in searchreplace est activ\xe9)",
  "Plugins installed ({0}):": "Plug-ins install\xe9s ({0})\xa0:",
  "Premium plugins:": "Plug-ins premium\xa0:",
  "Learn more...": "En savoir plus...",
  "You are using {0}": "Vous utilisez {0}",
  Plugins: "Plug-ins",
  "Handy Shortcuts": "Raccourcis utiles",
  "Horizontal line": "Ligne horizontale",
  "Insert/edit image": "Ins\xe9rer/modifier image",
  "Alternative description": "Description alternative",
  Accessibility: "Accessibilit\xe9",
  "Image is decorative": "L'image est d\xe9corative",
  Source: "Source",
  Dimensions: "Dimensions",
  "Constrain proportions": "Limiter les proportions",
  General: "G\xe9n\xe9ral",
  Advanced: "Options avanc\xe9es",
  Style: "Style",
  "Vertical space": "Espace vertical",
  "Horizontal space": "Espace horizontal",
  Border: "Bordure",
  "Insert image": "Ins\xe9rer une image",
  "Image...": "Image...",
  "Image list": "Liste des images",
  Resize: "Redimensionner",
  "Insert date/time": "Ins\xe9rer date/heure",
  "Date/time": "Date/heure",
  "Insert/edit link": "Ins\xe9rer/modifier lien",
  "Text to display": "Texte \xe0 afficher",
  Url: "URL",
  "Open link in...": "Ouvrir le lien dans...",
  "Current window": "Fen\xeatre active",
  None: "Aucun",
  "New window": "Nouvelle fen\xeatre",
  "Open link": "Ouvrir le lien",
  "Remove link": "Enlever le lien",
  Anchors: "Ancres",
  "Link...": "Lien...",
  "Paste or type a link": "Coller ou taper un lien",
  "The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?":
    "L'URL que vous avez saisi semble \xeatre une adresse e-mail. Souhaitez-vous y ajouter le pr\xe9fixe requis mailto:\xa0?",
  "The URL you entered seems to be an external link. Do you want to add the required http:// prefix?":
    "L'URL que vous avez saisi semble \xeatre un lien externe. Souhaitez-vous y ajouter le pr\xe9fixe requis mailto:\xa0?",
  "The URL you entered seems to be an external link. Do you want to add the required https:// prefix?":
    "L'URL que vous avez saisie semble \xeatre un lien externe. Voulez-vous ajouter le pr\xe9fixe https:// requis\xa0?",
  "Link list": "Liste des liens",
  "Insert video": "Ins\xe9rer une vid\xe9o",
  "Insert/edit video": "Ins\xe9rer/modifier une vid\xe9o",
  "Insert/edit media": "Ins\xe9rer/modifier un m\xe9dia",
  "Alternative source": "Source alternative",
  "Alternative source URL": "URL de la source alternative",
  "Media poster (Image URL)": "Affiche de m\xe9dia (URL de l'image)",
  "Paste your embed code below:": "Collez votre code incorpor\xe9 ci-dessous :",
  Embed: "Incorporer",
  "Media...": "M\xe9dia...",
  "Nonbreaking space": "Espace ins\xe9cable",
  "Page break": "Saut de page",
  "Paste as text": "Coller comme texte",
  Preview: "Aper\xe7u",
  Print: "Imprimer",
  "Print...": "Imprimer...",
  Save: "Enregistrer",
  Find: "Rechercher",
  "Replace with": "Remplacer par",
  Replace: "Remplacer",
  "Replace all": "Remplacer tout",
  Previous: "Pr\xe9c\xe9dente",
  Next: "Suivante",
  "Find and Replace": "Trouver et remplacer",
  "Find and replace...": "Trouver et remplacer...",
  "Could not find the specified string.": "Impossible de trouver la cha\xeene sp\xe9cifi\xe9e.",
  "Match case": "Respecter la casse",
  "Find whole words only": "Mot entier",
  "Find in selection": "Trouver dans la s\xe9lection",
  "Insert table": "Ins\xe9rer un tableau",
  "Table properties": "Propri\xe9t\xe9s du tableau",
  "Delete table": "Supprimer le tableau",
  Cell: "Cellule",
  Row: "Ligne",
  Column: "Colonne",
  "Cell properties": "Propri\xe9t\xe9s de la cellule",
  "Merge cells": "Fusionner les cellules",
  "Split cell": "Diviser la cellule",
  "Insert row before": "Ins\xe9rer une ligne avant",
  "Insert row after": "Ins\xe9rer une ligne apr\xe8s",
  "Delete row": "Supprimer la ligne",
  "Row properties": "Propri\xe9t\xe9s de la ligne",
  "Cut row": "Couper la ligne",
  "Cut column": "Couper la colonne",
  "Copy row": "Copier la ligne",
  "Copy column": "Copier la colonne",
  "Paste row before": "Coller la ligne avant",
  "Paste column before": "Coller la colonne avant",
  "Paste row after": "Coller la ligne apr\xe8s",
  "Paste column after": "Coller la colonne apr\xe8s",
  "Insert column before": "Ins\xe9rer une colonne avant",
  "Insert column after": "Ins\xe9rer une colonne apr\xe8s",
  "Delete column": "Supprimer la colonne",
  Cols: "Colonnes",
  Rows: "Lignes",
  Width: "Largeur",
  Height: "Hauteur",
  "Cell spacing": "Espacement entre les cellules",
  "Cell padding": "Marge int\xe9rieure des cellules",
  "Row clipboard actions": "Actions du presse-papiers des lignes",
  "Column clipboard actions": "Actions du presse-papiers des colonnes",
  "Table styles": "Style tableau",
  "Cell styles": "Type de cellule",
  "Column header": "En-t\xeate de colonne",
  "Row header": "En-t\xeate de ligne",
  "Table caption": "L\xe9gende de tableau",
  Caption: "L\xe9gende",
  "Show caption": "Afficher une l\xe9gende",
  Left: "Gauche",
  Center: "Centre",
  Right: "Droite",
  "Cell type": "Type de cellule",
  Scope: "\xc9tendue",
  Alignment: "Alignement",
  "Horizontal align": "Alignement horizontal",
  "Vertical align": "Alignement vertical",
  Top: "En haut",
  Middle: "Au milieu",
  Bottom: "En bas",
  "Header cell": "Cellule d'en-t\xeate",
  "Row group": "Groupe de lignes",
  "Column group": "Groupe de colonnes",
  "Row type": "Type de ligne",
  Header: "En-t\xeate",
  Body: "Corps",
  Footer: "Pied de page",
  "Border color": "Couleur de bordure",
  Solid: "Trait continu",
  Dotted: "Pointill\xe9",
  Dashed: "Tirets",
  Double: "Deux traits continus",
  Groove: "Sculpt\xe9",
  Ridge: "Extrud\xe9",
  Inset: "Incrust\xe9",
  Outset: "Relief",
  Hidden: "Masqu\xe9",
  "Insert template...": "Ins\xe9rer un mod\xe8le...",
  Templates: "Mod\xe8les",
  Template: "Mod\xe8le",
  "Insert Template": "Ins\xe9rer le mod\xe8le",
  "Text color": "Couleur du texte",
  "Background color": "Couleur d'arri\xe8re-plan",
  "Custom...": "Personnalis\xe9e...",
  "Custom color": "Couleur personnalis\xe9e",
  "No color": "Aucune couleur",
  "Remove color": "Supprimer la couleur",
  "Show blocks": "Afficher les blocs",
  "Show invisible characters": "Afficher les caract\xe8res invisibles",
  "Word count": "Nombre de mots",
  Count: "Total",
  Document: "Document",
  Selection: "S\xe9lection",
  Words: "Mots",
  "Words: {0}": "Mots\xa0: {0}",
  "{0} words": "{0} mots",
  File: "Fichier",
  Edit: "Modifier",
  Insert: "Ins\xe9rer",
  View: "Afficher",
  Format: "Format",
  Table: "Tableau",
  Tools: "Outils",
  "Powered by {0}": "Avec {0}",
  "Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help":
    "Zone de Texte Riche. Appuyez sur ALT-F9 pour le menu. Appuyez sur ALT-F10 pour la barre d'outils. Appuyez sur ALT-0 pour l'aide",
  "Image title": "Titre d'image",
  "Border width": "\xc9paisseur de la bordure",
  "Border style": "Style de la bordure",
  Error: "Erreur",
  Warn: "Avertir",
  Valid: "Valide",
  "To open the popup, press Shift+Enter": "Pour ouvrir la popup, appuyez sur Maj+Entr\xe9e",
  "Rich Text Area": "Zone de Texte Riche",
  "Rich Text Area. Press ALT-0 for help.": "Zone de Texte Riche. Appuyez sur ALT-0 pour l'aide.",
  "System Font": "Police syst\xe8me",
  "Failed to upload image: {0}": "\xc9chec d'envoi de l'image\xa0: {0}",
  "Failed to load plugin: {0} from url {1}":
    "\xc9chec de chargement du plug-in\xa0: {0} \xe0 partir de l\u2019URL {1}",
  "Failed to load plugin url: {0}": "\xc9chec de chargement de l'URL du plug-in\xa0: {0}",
  "Failed to initialize plugin: {0}": "\xc9chec d'initialisation du plug-in\xa0: {0}",
  example: "exemple",
  Search: "Rechercher",
  All: "Tout",
  Currency: "Devise",
  Text: "Texte",
  Quotations: "Citations",
  Mathematical: "Op\xe9rateurs math\xe9matiques",
  "Extended Latin": "Latin \xe9tendu",
  Symbols: "Symboles",
  Arrows: "Fl\xe8ches",
  "User Defined": "D\xe9fini par l'utilisateur",
  "dollar sign": "Symbole dollar",
  "currency sign": "Symbole devise",
  "euro-currency sign": "Symbole euro",
  "colon sign": "Symbole col\xf3n",
  "cruzeiro sign": "Symbole cruzeiro",
  "french franc sign": "Symbole franc fran\xe7ais",
  "lira sign": "Symbole lire",
  "mill sign": "Symbole milli\xe8me",
  "naira sign": "Symbole naira",
  "peseta sign": "Symbole peseta",
  "rupee sign": "Symbole roupie",
  "won sign": "Symbole won",
  "new sheqel sign": "Symbole nouveau ch\xe9kel",
  "dong sign": "Symbole dong",
  "kip sign": "Symbole kip",
  "tugrik sign": "Symbole tougrik",
  "drachma sign": "Symbole drachme",
  "german penny symbol": "Symbole pfennig",
  "peso sign": "Symbole peso",
  "guarani sign": "Symbole guarani",
  "austral sign": "Symbole austral",
  "hryvnia sign": "Symbole hryvnia",
  "cedi sign": "Symbole cedi",
  "livre tournois sign": "Symbole livre tournois",
  "spesmilo sign": "Symbole spesmilo",
  "tenge sign": "Symbole tenge",
  "indian rupee sign": "Symbole roupie indienne",
  "turkish lira sign": "Symbole lire turque",
  "nordic mark sign": "Symbole du mark nordique",
  "manat sign": "Symbole manat",
  "ruble sign": "Symbole rouble",
  "yen character": "Sinogramme Yen",
  "yuan character": "Sinogramme Yuan",
  "yuan character, in hong kong and taiwan": "Sinogramme Yuan, Hong Kong et Taiwan",
  "yen/yuan character variant one": "Sinogramme Yen/Yuan, premi\xe8re variante",
  Emojis: "\xc9mojis",
  "Emojis...": "\xc9mojis...",
  "Loading emojis...": "Chargement des emojis...",
  "Could not load emojis": "Impossible de charger les emojis",
  People: "Personnes",
  "Animals and Nature": "Animaux & nature",
  "Food and Drink": "Nourriture & boissons",
  Activity: "Activit\xe9",
  "Travel and Places": "Voyages & lieux",
  Objects: "Objets",
  Flags: "Drapeaux",
  Characters: "Caract\xe8res",
  "Characters (no spaces)": "Caract\xe8res (espaces non compris)",
  "{0} characters": "{0}\xa0caract\xe8res",
  "Error: Form submit field collision.":
    "Erreur\xa0: conflit de champs lors de la soumission du formulaire.",
  "Error: No form element found.": "Erreur : aucun \xe9l\xe9ment de formulaire trouv\xe9.",
  "Color swatch": "\xc9chantillon de couleurs",
  "Color Picker": "S\xe9lecteur de couleurs",
  "Invalid hex color code: {0}": "Code couleur hexad\xe9cimal invalide : {0}",
  "Invalid input": "Entr\xe9e invalide",
  R: "R",
  "Red component": "Composant rouge",
  G: "V",
  "Green component": "Composant vert",
  B: "B",
  "Blue component": "Composant bleu",
  "#": "#",
  "Hex color code": "Code couleur hexad\xe9cimal",
  "Range 0 to 255": "Plage de 0 \xe0 255",
  Turquoise: "Turquoise",
  Green: "Vert",
  Blue: "Bleu",
  Purple: "Violet",
  "Navy Blue": "Bleu marine",
  "Dark Turquoise": "Turquoise fonc\xe9",
  "Dark Green": "Vert fonc\xe9",
  "Medium Blue": "Bleu moyen",
  "Medium Purple": "Violet moyen",
  "Midnight Blue": "Bleu de minuit",
  Yellow: "Jaune",
  Orange: "Orange",
  Red: "Rouge",
  "Light Gray": "Gris clair",
  Gray: "Gris",
  "Dark Yellow": "Jaune fonc\xe9",
  "Dark Orange": "Orange fonc\xe9",
  "Dark Red": "Rouge fonc\xe9",
  "Medium Gray": "Gris moyen",
  "Dark Gray": "Gris fonc\xe9",
  "Light Green": "Vert clair",
  "Light Yellow": "Jaune clair",
  "Light Red": "Rouge clair",
  "Light Purple": "Violet clair",
  "Light Blue": "Bleu clair",
  "Dark Purple": "Violet fonc\xe9",
  "Dark Blue": "Bleu fonc\xe9",
  Black: "Noir",
  White: "Blanc",
  "Switch to or from fullscreen mode": "Passer en ou quitter le mode plein \xe9cran",
  "Open help dialog": "Ouvrir la bo\xeete de dialogue d'aide",
  history: "historique",
  styles: "styles",
  formatting: "mise en forme",
  alignment: "alignement",
  indentation: "retrait",
  Font: "Police",
  Size: "Taille",
  "More...": "Plus...",
  "Select...": "S\xe9lectionner...",
  Preferences: "Pr\xe9f\xe9rences",
  Yes: "Oui",
  No: "Non",
  "Keyboard Navigation": "Navigation au clavier",
  Version: "Version",
  "Code view": "Affichage du code",
  "Open popup menu for split buttons": "Ouvrir le menu contextuel pour les boutons partag\xe9s",
  "List Properties": "Propri\xe9t\xe9s de la liste",
  "List properties...": "Lister les propri\xe9t\xe9s...",
  "Start list at number": "Liste de d\xe9part au num\xe9ro",
  "Line height": "Hauteur de la ligne",
  "Dropped file type is not supported": "Le type de fichier d\xe9pos\xe9 n'est pas pris en charge",
  "Loading...": "Chargement...",
  "ImageProxy HTTP error: Rejected request": "Erreur HTTP d'ImageProxy : Requ\xeate rejet\xe9e",
  "ImageProxy HTTP error: Could not find Image Proxy":
    "Erreur HTTP d'ImageProxy : Impossible de trouver ImageProxy",
  "ImageProxy HTTP error: Incorrect Image Proxy URL":
    "Erreur HTTP d'ImageProxy : URL de ImageProxy incorrecte",
  "ImageProxy HTTP error: Unknown ImageProxy error":
    "Erreur HTTP d'ImageProxy : Erreur ImageProxy inconnue",
});
