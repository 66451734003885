export default {
  auth: {
    resetPasswordLink: {
      forgotPassword: "Forgot your password?",
    },
  },
  cards: {
    needLoginCard: {
      needLogged: "You need to be logged in to see this content.",
    },
    productHomeCard: {
      inceptionToDateReturn: "Inception-to-date return",
      lastDay: "Last day change",
      lastPrice: "Last price",
      na: "N/A",
      viewDetails: "View details",
      yearToDateReturn: "Year-to-date return",
    },
  },
  compliance: {
    cookies: {
      buttonText: "Accept",
      cookiesUsage:
        "This Website uses Cookies to improve the usability. By using this Website you declare your consent for Cookies to be used as well as with the privacy policy where you can find more information on Cookies. Link: {0}.",
      privacyPolicy: "Privacy policy",
    },
  },
  components: {
    downloadAsPngTooltip: "Download as PNG",
    details: {
      aumFormat: (ctx) =>
        `${ctx.named("currency")} ${(ctx.named("aum") / 1000000).toFixed(
          1
        )} million${ctx.named("aum") > 1000000 ? "s" : ""}${
          ctx.named("asOfDate") ? ` (as of ${ctx.named("asOfDate")})` : ""
        }`,
    },
  },
  datePickers: {
    dateRangePicker: {
      from: "From (yyyy-mm-dd)",
      fullPeriod: "Full period",
      to: "To (yyyy-mm-dd)",
    },
    zoomPicker: {
      fiveYears: "5Y",
      fullPeriod: "MAX",
      oneMonth: "1M",
      oneYear: "1Y",
      sixMonths: "6M",
      threeMonths: "3M",
      tenYears: "10Y",
      threeYears: "3Y",
      twoYears: "2Y",
      ytd: "YTD",
    },
  },
  dropdowns: {
    iconsDropdown: {
      noIcon: "No icon",
    },
  },
  editPrompt: {
    copyInAllLangs: "Copy in all languages",
    editField: "Edit field",
    enterText: "Enter a text",
    overwriteDataAllShareClasses: "Overwrite data of all share classes",
    id: "ID",
    idTooltip:
      "This unique identifier serves as the reference for the component within the platform. Each component you create needs a distinct identifier. We recommend the following convention for enhanced readability: use the component's title, convert to lowercase, replace spaces with dash. For a component titled 'My Analytic Table', the identifier would be 'my-analytic-table'.",
    needUniqueId:
      "The provided identifier is currently in use by another component in the current location or in a linked one. Please supply a distinctive identifier to ensure uniqueness.",
    numberOfDecimals: "Number of decimals",
    numberOfDecimalsTooltip:
      "The number of decimals for all the numbers displayed in the component. Note that this does not include graph axes.",
    withBenchmark: "Show with the benchmark",
    footnote: "Text below component",
    placeholderFootnote: "A text that appears below the component. E.g. 'Fees are included.'",
    langTabError: "Some fields are invalid in the following languages:",
    settingsOnlyUsedOnOverviewTooltip: "This setting exclusively affects the 'Overview' page.",
    showOnFactsheet: "Show on Factsheet",
    tabError: "Some fields are invalid",
    title: "Title",
    titleEdit: (ctx) => `Edit ${ctx.named("componentId")}`,
    titleAdd: "Add component",
    unsavedChangesAlert:
      "You have unsaved changes. If you leave without saving, they will be lost.",
    genericComponent: {
      addColumn: "Add column",
      addRow: "Add row",
      addData: "Add data",
      barChart: "Bar chart",
      cantChangeType:
        "You can't change the type of a generic component once you have saved it. If you want a different type, you need to create a new generic component.",
      columnWithIndex: (ctx) => `Column ${ctx.named("idx")}`,
      data: "Data",
      date: "Date",
      donutChart: "Donut chart",
      exampleSector: "E.g., Sectors",
      header: "Header",
      key: "Key",
      image: "Image",
      lineChart: "Line chart",
      needData:
        "You can't save a generic component without any data or only partial data. Delete it, add data or complete present data before saving.",
      pieChart: "Pie chart",
      removeData: "Remove data",
      showColumnHeader: "Show column header",
      table: "Table",
      text: "Text",
      type: "Type",
      useBrandColor: "Use brand color",
      value: "Value",
      weightPercent: "Weight (in %)",
    },
    contacts: {
      addContact: "Add contact",
      additionalText: "Additional text",
      email: "Email",
      exampleEmail: "E.g., jdoe@mail.com",
      exampleName: "E.g., John Doe",
      examplePhone: "E.g., +41 79 123 45 67",
      exampleRole: "E.g., Partner",
      imageSquare: "Image (square)",
      name: "Name",
      phone: "Phone",
      removeContact: "Remove contact",
      role: "Role",
    },
    dialog: {
      copyInAllLangs: {
        title: "Copy in all languages",
        text: "You are going to copy all the values of the current language to all other languages. Are you sure?",
      },
      saveFieldInAllShareClasses: {
        title: "Overwrite",
        text: "You are going to overwrite this field in all other share classes of this product, not only the current share class. Are you sure?",
      },
      saveInAllShareClasses: {
        title: "Overwrite",
        text: (ctx) =>
          `You are going to overwrite the "${ctx.named(
            "tabName"
          )}" data of all other share classes of this product with the "${ctx.named(
            "tabName"
          )}" data of this share class (not only the last changes you made). Are you sure?`,
      },
    },
    description: {
      contentTitle: "Description",
    },
    disclaimer: {
      contentTitle: "Disclaimer",
      defaultWarning:
        "You currently do not have a disclaimer for this product. Therefore, the standard disclaimer has been used. If you write a disclaimer here, it will only be displayed for this product.",
    },
    historicalPricesChart: {
      defaultLogScale: "By default in log scale",
      showLogScaleSwitch: "Show log scale switch",
      priceIndexedTo100: "Indexed to 100",
    },
    holdingOverTime: {
      elementsInOthers: {
        title: 'Elements to consider as "Others"',
        multiSelect: {
          placeholder: "Select elements",
          selectLabel: "Press enter to select",
          deselectLabel: "Press enter to remove",
          selectedLabel: "Selected",
          limitText: (ctx) => `and ${ctx.named("count")} more`,
        },
      },
    },
    keyCharacteristics: {
      addKeyCharacteristics: "Add key characteristics",
      exampleDynamicExposure: "E.g., Dynamic exposure",
      exampleWeDynamicallyAdapt: "E.g., We dynamically adapt ...",
      header: "Header",
      icon: "Icon",
      paragraph: "Paragraph",
      removeKeyCharacteristics: "Remove key characteristic",
    },
    monthlyReturnsTable: {
      descending: "Sort years in descending order",
      startDate: "Start date",
      startDateTooltip:
        "Select a start date (in YYYY-MM-DD format) for the return table. The final table will automatically adjust to display data starting from the earliest available date for the product or your chosen date, whichever is later. Leaving this field blank will display all available price data for the product.",
      benchmark: "Benchmark",
      product: "Product",
      return: "Return",
      nameReplacements: "Replace default names",
      nameReplacementsTooltip:
        "Customize the product and benchmark names in the component. Leave blank to default to the existing names.",
    },
    notify: {
      componentSaveSuccess: (ctx) =>
        `Your component '${ctx.named("componentId")}' was updated successfully.`,
    },
    overviewHeader: {
      backgroundImage: "Background image",
      backgroundImageTooltip:
        "Please be aware that the image currently under consideration is also utilized as the product card image on the homepage. Consequently, any alterations to this image will simultaneously affect the product's representation on the homepage.",
      text: "Text",
      textTooltip:
        "Please be aware that the text currently under consideration is also utilized as the product card text on the homepage. Consequently, any alterations to this text will simultaneously affect the product's representation on the homepage.",
      saveSuccess: "Header was updated successfully.",
      showDownloadLastFactsheetButton:
        'Show "@:components.extras.downloadLastFactsheet.downloadLastFactsheet" button',
      showDownloadLastFactsheetButtonTooltip:
        'For the button to be visible for a user, a document should be marked as "@:pages.products.documents.prompt.uploadFile.shouldConsiderLastFactsheet" within the "Documents" section, and it should be available in the selected language of the user.',
      showFollowButton: "Show follow button",
      title: "Edit overview header",
    },
    predefinedHoldingComponent: {
      chartType: "Chart type",
      barChart: "Bar chart",
      donutChart: "Donut chart",
      pieChart: "Pie chart",
      table: "Table",
      useBrandColor: "Use brand color",
      assetClasses: {
        editAssetClasses: "Edit asset classes",
      },
      currencyExposure: {
        editCurrencyExposure: "Edit currency exposure",
      },
      instrumentTypes: {
        editInstrumentTypes: "Edit instrument types",
      },
      onlyShowTopThenOthers: {
        title: "Show top only",
        tooltip:
          'Only show the top X (chosen number), and merge the remaining ones in "Others". Let empty to show all.',
      },
      regions: {
        editRegions: "Edit regions",
      },
      sectors: {
        editSectors: "Edit sectors",
      },
    },
    productDetails: {
      addDetail: "Add detail",
      exampleIsin: "E.g., ISIN",
      exampleLU: "E.g., LU2220388479",
      key: "Key",
      noteDragAndDrop: "Note: Drag and drop rows to reorder the details.",
      removeDetail: "Remove detail",
      showAuM: "Show AuM",
      showLastPrice: "Show last price",
      value: "Value",
    },
    relativePerformanceChart: {
      footnote: "Footnote",
      placeholderFootnote: "A text that appears below the chart. E.g. 'Fees are included.'",
    },
    riskProfile: {
      level: "Level of risk",
    },
    shareClassesTable: {
      bloomberg: "Bloomberg",
      class: "Class",
      defaultFootnote:
        "SI perf.: returns for since inception periods of more than one year are annualized, and not annualized if less than one year.",
      footnote: "Footnote",
      inceptionDate: "Inception date",
      isin: "ISIN",
      mgmtFee: "Mgmt. fee",
      perfFee: "Perf. fee",
      title: "Edit share classes table",
    },
    statistics: {
      benchmark: "Benchmark",
      product: "Product",
      nameReplacements: "Replace default names",
      nameReplacementsTooltip:
        "Customize the product and benchmark names in the component. Leave blank to default to the existing names. For example, use a term like 'Product' to abbreviate and save display space.",
      fieldsToDisplay: "Fields to display",
      noteDragAndDrop: "Note: Drag and drop rows to reorder the statistics displayed.",
      shownStatisticName: "Shown statistic name",
      showStatistic: "Show statistic",
      statisticId: "Statistic id",
    },
    statisticsWithPeriods: {
      benchmark: "Benchmark",
      product: "Product",
      nameReplacements: "Replace default names",
      nameReplacementsTooltip:
        "Customize the product and benchmark names in the component. Leave blank to default to the existing names. For example, use a term like 'Product' to abbreviate and save display space.",
      fieldsToDisplay: "Fields to display",
      noteDragAndDrop: "Note: Drag and drop rows to reorder the statistics displayed.",
      periodsTitle: "Periods to show",
      shownStatisticName: "Shown statistic name",
      showStatistic: "Show statistic",
      statisticId: "Statistic id",
      periods: {
        1: "1M",
        3: "3M",
        6: "6M",
        ytd: "YTD",
        12: "1Y",
        36: "3Y",
        60: "5Y",
        max: "MAX",
      },
    },
    topHoldings: {
      fieldsToDisplay: "Fields to display",
      showHeader: "Show header",
      showTotal: "Show total",
      numHoldings: {
        title: "Number of holdings to show",
        tooltip:
          "If you leave this field blank, all available data for the product's holdings will be shown.",
      },
      holdingsToIgnore: {
        title: "Holdings to ignore",
        holdingsMultiselect: {
          placeholder: "Select holdings to ignore",
          selectLabel: "Press enter to select",
          deselectLabel: "Press enter to remove",
          selectedLabel: "Selected",
          limitText: (ctx) => `and ${ctx.named("count")} more`,
        },
      },
      customizeTable: "Customize table",
      addColumn: "Add column",
      columnWithIndex: (ctx) => `Column ${ctx.named("idx")}`,
    },
  },
  extras: {
    changeRoles: {
      changeRoles: "Change roles",
      notify: {
        errorText: "No roles have been updated. Reason: ",
        successText: "User roles have been updated",
      },
    },
    deleteGenericComponent: {
      delete: "Delete",
      deleteAllComponents: "Delete all components",
      deleteOnlyThisComponent: "Delete only this component",
      deleting: "Deleting ...",
      deleteSuccess: "Deletion successful.",
      page: "Page",
      shareClass: "Share class",
      textIsNotLinked:
        "You are about to permanently delete a generic component. This action cannot be undone. Please confirm that you wish to proceed with deletion.",
      textIsLinkedFirstPart:
        "You are about to permanently delete a generic component. This action cannot be undone.  Note that this component is linked with the same component in the following: ",
      textIsLinkedSecondPart:
        "Please confirm that you wish to proceed with the deletion, and decide if you wish to delete only the current component, or if you wish to delete this component and all the linked components.",
      title: (ctx) => `Permanent deletion of ${ctx.named("componentId")}`,
      pageList: {
        factsheet: "Factsheet",
        overview: "Overview",
      },
    },
    downloadLastFactsheet: {
      downloadLastFactsheet: "Download last factsheet",
      downloadingLastFactsheet: "Downloading...",
      needLogin: {
        text: "In order to download this document, you need to be authenticated.",
        title: "You need to be authenticated",
      },
    },
    manualVerifyEmail: {
      forceVerification: "Force email verification",
      verified: "Email verified",
      notify: {
        errorText: "Force email verification failed.",
        successText: "User email has been manually verified.",
      },
    },
    riskProfile: {
      higherRisk: "Higher risk",
      lowerRisk: "Lower risk",
      potentiallyHigherReward: "Potentially higher reward",
      potentiallyLowerReward: "Potentially lower reward",
    },
  },
  factsheet: {
    lastPrice: "Last price",
  },
  graphs: {
    logScale: "Log scale",
    notEnoughData: "Not enough data to plot this graph, please select a longer date range.",
    needMoreData:
      "To compute this metric, a certain amount of data at the beginning is necessary. Enlarge the timeframe to get access to this metric.",
  },
  holdingData: {
    others: "Others",
  },
  images: {
    uploadImage: {
      darkening: "Darkening",
      file: "File",
      noFile: "No file",
      noImage: "No image",
      selectFile: "Select file",
      selectNewImage: "Select new image",
      errorTitleTooLarge: "Image too large",
      errorMessageTooLarge:
        "The image is too large. Please, select another image with a size smaller than 30 MB.",
      warningTitleVeryLarge: "Image very large",
      warningMessageVeryLarge:
        "The image is very large. It will make the pages displaying this image slower to load. For best performance, it is advised to use images smaller than 10 MB.",
    },
  },
  linkPrompt: {
    componentLinkedTo: "This component is linked to:",
    currentPage: "Current page",
    currentShareClass: "Current share class",
    linkThroughPagesTooltip:
      'Enabling this feature creates a synchronized connection between components across multiple pages. For instance, linking the "Factsheet" and "Overview" pages ensures any edits to a shared component are updated on both pages simultaneously. Important: The content of the linked component is duplicated from the source page you\'re currently editing. If you initiate the link while editing the "description" component of the "Factsheet" page, the "description" component in "Factsheet" is copied to the "Overview" and will thus override the "description" component of "Overview".',
    linkThroughShareClassesTooltip:
      'Enabling this feature creates a synchronized connection between components across multiple share classes. For instance, linking the "My Product Share A" and "My Product Share B" ensures any edits to a shared component are updated on both share classes simultaneously. Important: The content of the linked component is duplicated from the source page you\'re currently editing. If you initiate the link while editing the "description" component of the "My Product Share A" page, the "description" component in "My Product Share A" is copied to the "My Product Share B" and will thus override the "description" component of "My Product Share B".',
    linkedWithPages: "Linked with the following pages",
    linkedWithShareClasses: "Linked with the following share classes",
    page: "Page",
    shareClass: "Share class",
    title: (ctx) => `Edit linking of ${ctx.named("componentId")}`,
    unlink: "Unlink",
    unlinking: "Unlinking ...",
    unsavedChangesAlert:
      "You have unsaved changes. If you leave without saving, they will be lost.",
    confirmSaveDialog: {
      computingNewLinks: "Computing new links...",
      onceLinked:
        "Once linked, when a change is applied to one component in any of those locations (including the current one), all the other linked components will be updated in the same way. Please confirm that you wish to proceed with saving.",
      overwrittenExistingComponent: "Overwriting a component with the same id",
      overwrittenExistingComponentTooltip: (ctx) =>
        `Warning: There is already a component with ID '${ctx.named(
          "componentId"
        )}' at this location, so it will be overwritten by this component if you link it.`,
      title: "Confirm save of new links",
      youAreAboutToLink:
        "You are about to link the current component with the components in the following locations:",
    },
    notify: {
      linkSaveSuccess: (ctx) =>
        `The linking update of '${ctx.named("componentId")}' was successful.`,
    },
    multiselect: {
      placeholder: "Select pages to link",
      selectLabel: "Press enter to select",
      deselectLabel: "Press enter to remove",
      selectedLabel: "Selected",
    },
    overviewHeader: {
      title: "Edit linking of overview header",
      saveSuccess: "The linking update of the header was successful.",
    },
    pageList: {
      factsheet: "Factsheet",
      overview: "Overview",
      analysis: "Analysis",
    },
  },
  pricesManagement: {
    addData: "Add data",
    autoDataUpdate: "Your data is updated automatically.",
    date: "Date",
    datePlaceholder: "YYYY-MM-DD",
    dateValidationError: "You can't add a date that already has a value.",
    exportCsv: "Export as CSV",
    importCsv: "Import from CSV",
    importCsvFormat:
      "The input file is expected to be in CSV format, with two columns and a header row. The first column should consist of dates, and the second column should consist of numbers (the price at the corresponding date). Please ensure that your CSV is valid to avoid any issues when reading and parsing the data.",
    loadingData: "Loading data...",
    price: "Price",
    removeData: "Remove data",
    benchmarks: {
      noBenchmark: "No benchmark available for this product.",
    },
    dialog: {
      removeData: {
        title: "Remove a data point",
        text: "You are going to completely remove a data point. Are you sure?",
      },
    },
    notify: {
      dateUsedMultipleTimeError:
        "There are multiple prices associated with one date. You have to ensure that each date has a unique price assigned to it.",
      numberFormatError:
        'At least one of the numbers has an unrecognized format. Ideally, you should not use any separators for thousands and use a dot for the decimal separator as this is the expected format. For example, "102.12", "1380.25", "18.952", ...',
      saveErrorText: "Error when updating prices, please contact us.",
      saveSuccessText: "The prices were updated successfully.",
    },
    prompt: {
      addPrice: "Add a price",
      importCsv: "Import data from CSV",
    },
  },
  tables: {
    metricsTable: {
      na: "N/A",
      name: "Name",
      showLess: "Show less",
      showMore: "Show more",
    },
    monthlyReturnsTable: {
      benchmark: "Benchmark",
      return: "Return",
    },
    performanceTable: {
      computedFromTo: (ctx) =>
        `Performance computed from ${ctx.named("firstDate")} to ${ctx.named("lastDate")}.`,
      notAvailableData:
        "Data not available either because there isn't enough days to compute it, the data is not meaningful (e.g. negative sharpe ratio), or the benchmark is not always available during the selected period",
    },
    topHoldingsTable: {
      holdingFields: {
        assetClass: "Asset class",
        currency: "Currency",
        holdingName: "Name",
        instrumentType: "Instrument type",
        isin: "ISIN",
        region: "Region",
        sector: "Sector",
        value: "Value",
        weight: "Weight",
      },
      totalOfPortfolio: "Total of portfolio",
    },
  },
  thirdParty: {
    vuePhoneNumberInput: {
      countrySelectorLabel: "Country code",
      countrySelectorError: "Select a country",
      phoneNumberLabel: "Phone number",
      example: "Example :",
    },
  },
};
