import { cloneDeep } from "lodash";

import { Role } from "@/acl/roles";
import { GenericProductCards } from "@/assets/constants/analysisCards";
import { EventDate, GLOBAL_EVENTS } from "@/assets/constants/eventsDates";
import { UpdateFrequency } from "@/assets/constants/frequencies";
import {
  STANDARD_PERF_STATS_WITH_BENCHMARK,
  STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
} from "@/assets/constants/perfStats";
import { STANDARD_ZOOM_BUTTONS } from "@/assets/constants/zoomButtons";
import exports from "@/assets/scss/exports.module.scss";

const ORG_COLOR = exports["colors-primary"];
const INTERNAL_ALLOWED_ROLES = [Role.admin, Role.superAdmin];

const newLuxury = {
  productId: "new-luxury",
  storeModule: "newLuxury",
  productName: "New Luxury",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: false,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS.concat([EventDate.utiNewLuxuryInception]),
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const artificialIntelligence = {
  productId: "artificial-intelligence",
  storeModule: "artificialIntelligence",
  productName: "Artificial Intelligence",
  productColor: ORG_COLOR,
  showLastPrice: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: false,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const lsts = {
  productId: "lsts",
  storeModule: "lifeSciencesToolsAndServices",
  productName: "Alquant Life Sciences Tools and Services",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const newHealthAndMedicines = {
  productId: "new-health-and-medicines",
  storeModule: "newHealthAndMedicines",
  productName: "New Health & Medicines",
  productColor: ORG_COLOR,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

// ******---------- NEW FINANCIALS ----------*****

const newFinancialsUsd = {
  productId: "new-financials-usd",
  storeModule: "newFinancialsUsd",
  productName: "New Financials USD",
  productShortName: "New Financials",
  productColor: ORG_COLOR,
  productShareClassRoot: "new-financials",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
};

const newFinancialsChf = cloneDeep(newFinancialsUsd);
newFinancialsChf.productId = "new-financials-chf";
newFinancialsChf.storeModule = "newFinancialsChf";
newFinancialsChf.productName = "New Financials CHF";
newFinancialsChf.isDefaultShareClass = false;

const newFinancialsEur = cloneDeep(newFinancialsUsd);
newFinancialsEur.productId = "new-financials-eur";
newFinancialsEur.storeModule = "newFinancialsEur";
newFinancialsEur.productName = "New Financials EUR";
newFinancialsEur.isDefaultShareClass = false;

export const UTI_PRODUCTS_DETAILS = [
  newLuxury,
  artificialIntelligence,
  lsts,
  newHealthAndMedicines,
  newFinancialsUsd,
  newFinancialsChf,
  newFinancialsEur,
];

export const UTI_PRODUCTS = UTI_PRODUCTS_DETAILS.map((details) => details.storeModule);
